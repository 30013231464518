
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Dropdown from '../../Components/Dropdown';

import { applyStyle, getStyleChar } from './StyleControlsUtils';
import { FontFamilyMap} from './StyleMaps';
import FontManager from '../../utils/FontManager';

import {FontFamilies} from './FontFamilies';
import Tooltip from 'Components/Common/Tooltip/Tooltip';

const ControlStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: initial;
  text-align: right;
  align-items: center;
  z-index: 1003;
  .no-edit {
    .name {
      display: inline-block;
      width: 50px;
    }
    .warning {
      color: yellow;
    }
  }
  width: 180px
`;



const getOptionsStyles = () => FontFamilies.map(el => ({
  ...el,
  style: {
    'fontFamily': el.label
  }
})).sort((a, b) => ((a.label > b.label) ?  1 : -1))

const FontStyleControls = (props) => {
    const [value, setValue] = useState("Roboto");

    useEffect(() => {
        const newValue = getValue(props.editorState);
        setValue(newValue);
      }, [props.editorState]);

    const onChange = (item, name) => {
        console.log('font change', item);
        let fontName = item.value;
        setValue(fontName );

        FontManager.getInstance().loadFont( fontName );

        const style = 'font-' + fontName;

        let nextEditorState = applyStyle( props.block, props.editorState, style, FontFamilyMap);
        props.onChange(nextEditorState);
    };

    const getFontFromChar = (char) => {
        if (!char) {
          return 'Quicksand';
        }

        const arr = char.getStyle().toArray();
        let font = 'Quicksand'; //default font
        arr.forEach((style) => {
          if (style.search('font-') === 0) {
            font = style.substring(5);
          }
        });
        return font;
      };

    const getValue = (editorState) =>{
        let char = getStyleChar( editorState );
        return getFontFromChar( char );
    }

    return (
      <Tooltip text='Font'>
        <ControlStyle>
          <Dropdown
            searchable={true}
            name="font"
            title={getOptionsStyles(FontFamilies).find((item) => item.value == value)?.label}
            list={getOptionsStyles(FontFamilies)}
            select={value}
            onChange={onChange}
            // styles={{headerTitle: getOptionsStyles(FontFamilies).find((item) => item.value == value)?.style}}
          />
        </ControlStyle>
      </Tooltip>
    )
}

export default FontStyleControls;