import React, {useState, useEffect} from 'react';
import {IMask, useIMask} from 'react-imask';
import s from './AttributeTime.module.scss'
import {eventEmiter} from '../../rx/rxState';

const AttributeTime = (props) => {

    // pattern types
    // 1. 'date' - DD:HH:MM:SS
    // 2. 'time' - HH:MM:SS

    const [dateValue, setDateValue] = useState(props.attribute.value)
    const [isErrorActive, setIsErrorActive] = useState(false)
    const [isEmpty, setIsEmpty] = useState(true)

    const optionsFirst = {
        mask: '00 : 00 : 00 : 00',
        lazy: false,
        overwrite: true,
        autofix: true,
        // blocks: {
        //     DD: {
        //         mask: IMask.MaskedRange,
        //         placeholderChar: '0',
        //         from: 0,
        //         to: 31
        //     },
        //     HH: {
        //         mask: IMask.MaskedRange,
        //         placeholderChar: '0',
        //         from: 0,
        //         to: 23
        //     },
        //     MM: {
        //         mask: IMask.MaskedRange,
        //         placeholderChar: '0',
        //         from: 0,
        //         to: 59
        //     },
        //     SS: {
        //         mask: IMask.MaskedRange,
        //         placeholderChar: '0',
        //         from: 1,
        //         to: 59
        //     }
        // }
    }

    const optionsSecond = {
        mask: '00 : 00 : 00',
        lazy: false,
        overwrite: true,
        // autofix: true,
        // blocks: {
        //     HH: {
        //         mask: IMask.MaskedRange,
        //         placeholderChar: '0',
        //         from: 0,
        //         to: 23
        //     },
        //     MM: {
        //         mask: IMask.MaskedRange,
        //         placeholderChar: '0',
        //         from: 0,
        //         to: 59
        //     },
        //     SS: {
        //         mask: IMask.MaskedRange,
        //         placeholderChar: '0',
        //         from: 1,
        //         to: 59
        //     }
        // }
    }

    const {ref, maskRef} = useIMask((props.attribute.pattern === 'date' && optionsFirst)
        || (props.attribute.pattern === 'time' && optionsSecond))

    const onChange = (value) => {
        let attr = props.attribute;

        if (props.attribute.pattern === 'date' && ref.current.value.match(regexFirst) ||
            props.attribute.pattern === 'time' && ref.current.value.match(regexSecond)) {
            setIsErrorActive(false);
            const splittedInterval = value.split(':');
            let intervalInSeconds = calcIntervalInSeconds(splittedInterval);

            // integrate logic of setting value in seconds here
            
        } else {
            setIsErrorActive(true);
        }

        props.onChange(attr.id, value);
        setDateValue(value);
        eventEmiter.next({
            type: 'update-view'
        })
    }

    useEffect(() => {
        if (props.attribute.value) {
            maskRef.current.typedValue = props.attribute.value;
        }

        if (dateValue === '00 : 00 : 00 : 00'
            || dateValue === '00 : 00 : 00') {
            setIsEmpty(true)
        } else {
            setIsEmpty(false)
        }
    })

    const regexFirst = /[0-9]{2}(\s)[:](\s)[0-9]{2}(\s)[:](\s)[0-9]{2}(\s)[:](\s)[0-9]{2}/g;
    const regexSecond = /[0-9]{2}(\s)[:](\s)[0-9]{2}(\s)[:](\s)[0-9]{2}/g;

    const calcIntervalInSeconds = (splittedInterval) => {
        if (splittedInterval.length === 3) {
            return (+splittedInterval[0]) * 60 * 60 + (+splittedInterval[1]) * 60 + (+splittedInterval[2]);
        } else if (splittedInterval.length === 4) {
            return (+splittedInterval[0]) * 24 * 60 * 60 + (+splittedInterval[1]) * 60 * 60 + (+splittedInterval[2]) * 60 + (+splittedInterval[3]);
        }
    }

    return (
        <div className={s.container}>
            <div className={s.label}>Length</div>
            <div className={s.content}>
                <input
                    className={isErrorActive && s.error}
                    onInput={() => {
                        let intervalWithoutSpaces = ref.current.value.replace(/\s/g, '');
                        onChange(intervalWithoutSpaces);
                    }} ref={ref} style={{
                    width: `${(props.attribute.pattern === 'date' && '129px')
                    || (props.attribute.pattern === 'time' && '97px')}`,
                    color: `${isEmpty ? '#CED2D9' : '#000000'}`
                }}/>
            </div>
        </div>
    );
};

export default AttributeTime;