import { AUTH_USER_TOKEN_KEY } from './Constants';
import { Auth } from 'aws-amplify';
import jwtDecode from 'jwt-decode';

export const validateToken = (token) => {
  return new Promise(async (resolve, reject) => {
    if (!token) {
      reject("Token is Empty");
    }
    try {
      const decodedJwt = jwtDecode(token);

      if(decodedJwt.exp >= Date.now() / 1000){
        resolve(token);
      }
      else{
        refreshToken()
        .then( newToken => resolve( newToken ))
        .catch( error => reject( error ))
      }
    } catch (e) {
      return false;
    }
  })
};

export const username = (token) => {
  if (!token) {
    return '';
  }
  try {
    const decodedJwt = jwtDecode(token);
    return decodedJwt.username;
  } catch (e) {
    return '';
  }
};

export const refreshToken = ()=>{
  return new Promise(async (resolve, reject) => {
    console.log('refreshing token');
    let user = await Auth.currentAuthenticatedUser();
    let refreshToken = user.getSignInUserSession().getRefreshToken();      
    user.refreshSession(refreshToken, (refErr, refSession) => {
      if (refErr) {
        console.log(refErr);
        reject(refErr);

        // throw refErr;
      }
      else{
        localStorage.setItem(
          AUTH_USER_TOKEN_KEY,
          refSession.accessToken.jwtToken
        );
        console.log('token refreshed');
        resolve(refSession.accessToken.jwtToken);
      }
    }); 
  });
}

