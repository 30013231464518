import React, {useState, useEffect} from 'react';
import { ButtonDef } from './ToolbarManipulator';
import s from './ToolbarManipulatorButton.module.scss';

const ToolbarManipulatorButton = (props: ButtonDef) => {
    const handleMouseDown = (e:any) => {
        e.preventDefault();
        if(props.onMouseDown){
            props.onMouseDown(props.type, e);
        }
    }

    const handleMouseUp = (e:any) => {
        e.preventDefault();
        if(props.onClick){
            props.onClick(props.type);
        }
    }

    return (
        <div className={ props.submenu ? s.subButton : s.button} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
            <div className={s.inside}>
                <img className={s.icon} draggable={false} src={props.image} />
                {
                    props.submenu && 
                    <div className={s.name}>{props.name}</div> 
                }
            </div>
        </div>
    )
}

export default ToolbarManipulatorButton;