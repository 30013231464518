import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  IBookChapter,
  IBookData,
  BookStateType,
} from 'Components/books/types';
import s from './BookGenerator.module.scss';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';

import { SOCKET_URL } from 'Constants';
import { io, Socket } from 'socket.io-client';
import BookEditorView from 'Components/books/BookEditorView';
import { AIBookGenerator } from 'Components/books/bookGeneration';
import {
  rxBlocks,
  rxBookDataForSave,
  rxBookNeedSave,
  rxInsertSection,
  rxReplaceSection,
  rxRemoveSection,
  rxDuplicateSection,
  rxBlockIndexForBook,
  rxSections,
  rxSelectBookImg,
  rxReorderSection,
  rxActiveChapterIndex
} from 'rx/rxState';
import { useObservable } from 'utils/UseObservable';
import SaveSpinner from 'Components/Common/SaveSpinner/SaveSpinner';
import BookInfoBlockEditor from 'Components/books/BookInfoBlock/BookInfoBlockEditor';
import { getBySocket } from 'utils/socket';
import { cloneDeep } from 'lodash';
import { getToken } from 'utils/api';
import { setIdsOrder } from 'utils/blocks';

interface IChaptersGenerationResponse {
  title: string;
  text: string;
}

function extractChapterTitle(string: string) {
  const chapterRegex = /^(?:\w+\s+)*Chapter\s+(\d+):?\s+(.+)/;
  const match = string.match(chapterRegex);

  if (match) {
    return match[2];
  } else {
    return string;
  }
}

const IMAGE_SECTION_TITLE = 'Image page';

const BookEditor = () => {
  const { bookId } = useParams<any>();
  /**
   * State
   */
  const [globalStatus, setGlobalStatus] = useState<BookStateType>('INITIATING');
  const [bookData, setBookData] = useState<IBookData | null>(null);
  const [bookSaveLoading, setBookSaveLoading] = useState<boolean>(false);
  const [chapterInProgress, setChapterInProgress] = useState<null|number>(null);
  const [allChaptersGenerationResponse, setAllChaptersGenerationResponse] = useState<IChaptersGenerationResponse | null>(null);
  const [bookCoverFromSocket, setBookCoverFromSocket] = useState<null|string>(null);

  /**
   * Observable RX
   */
  const bookDataForSave: any = useObservable(rxBookDataForSave);
  const sectionToRemove: string | undefined = useObservable(rxRemoveSection);
  const sectionToDuplicate: string | undefined = useObservable(rxDuplicateSection);
  const blocks: any = useObservable(rxBlocks);
  const addBlankChapterIndex = useObservable(rxBlockIndexForBook);
  const selectBookImg: any = useObservable(rxSelectBookImg);

  const socket = useRef<Socket | null>(null);

  useEffect(() => {
    fetchInitialData();
    socket.current = io(SOCKET_URL);
    getChaptersBySocket();

  }, []);

  useEffect(() => {
    if (chapterInProgress) {
      return;
    }
    var data = addBlankChapterIndex as any;    
    const index = data?.index ;
    if(index){
      if (index === 0) {
        recoverBookCover();
      }
      if (!index) {
        return;
      }
      addImageChapter(index)
    }
  }, [addBlankChapterIndex]);

  useEffect(() => {
    if (sectionToRemove === undefined || sectionToRemove === null || !blocks || !bookData) {
      return;
    }

    const index = (sectionToRemove as any)?.index;
    if( index ){
      handleDeleteChapterFromEditor(index);
    }
    
  }, [sectionToRemove]);


  useEffect(() => {
    if (sectionToDuplicate === undefined || sectionToDuplicate === null || !blocks || !bookData) {
      return;
    }

    const index = (sectionToDuplicate as any)?.index;
    if( index ){
      handleDuplicateChapterFronEditor(index);
    }

  }, [sectionToDuplicate]);

  const recoverBookCover = () => {
    setChapterInProgress(-1);
    if (!bookData) {
      setChapterInProgress(null);
      return;
    }
    const firstBlock = blocks.blocks[0];
    if (firstBlock.image && firstBlock.children?.length) {
      rxRemoveSection.next({index:0});
    }
    let section: any;
    if (bookData.data?.blocks?.length && bookData.data.blocks[0].image && bookData.data.blocks[0].children.length) {
      section = bookData.data.blocks[0];
    } else {
      const generator = new AIBookGenerator();
      const generatedBlocks = generator.createInitialData(bookData.title, selectBookImg || bookData.coverImageUrl);
      section = generatedBlocks.blocks[0];
    }

    const optimizedData = setIdsOrder([section])
    rxInsertSection.next({
      index: 0,
      section: optimizedData.blocks[0],
    });
    setChapterInProgress(null);
  }

  const handleDuplicateChapterFronEditor =  (index: number) => {
    if (!bookData) {
      return;
    }
    const newChapters = [...bookData.chapters];
    const chapter = newChapters[index-1];
    newChapters.splice(index - 1, 0, chapter);
    const newBookData = cloneDeep(bookData);
    newBookData.chapters = newChapters;
    setBookData(newBookData);
  
  }

  const handleDeleteChapterFromEditor = (index: number) => {
    if (!bookData) {
      return;
    }
    const newChapters = [...bookData.chapters];
    newChapters.splice(index - 1, 1);
    const newBookData = cloneDeep(bookData);
    newBookData.chapters = newChapters;
    setBookData(newBookData);
  }

  const fetchInitialData = async () => {
    await getTemplateSections();
    await fetchBookData(bookId);
  }

  const fetchBookData = async (bookId: string) => {
    try {
      const newBookData: IBookData = await graphQlCall({
        queryTemplateObject: queries.GET_BOOK_FOR_EDIT_BY_ID,
        headerType: 'USER-AUTH',
        values: {
          id: bookId,
        },
      });

      if (!newBookData) {
        throw new Error(`fetching book error`);
      }
      setBookData(newBookData);

      const initialGlobalStatus = calculateInitialState(newBookData);
      setGlobalStatus(initialGlobalStatus);
    } catch (error) {
      const url = `/console/login`;
      window.open(url, '_self');
    }
  }

  const calculateInitialState = (bookData: IBookData): BookStateType => {
    const sectionsCount = bookData.data?.blocks?.length || 0;
    const chaptersCount = bookData.chapters.length;

    let chaptersGeneratedCount = 0;
    bookData.chapters.forEach((chapter) => {
      if (chapter.text?.length || chapter.title === IMAGE_SECTION_TITLE) {
        chaptersGeneratedCount++;
      }
    });
    if (chaptersGeneratedCount === 0) {
      return 'INITIAL_GENERATION';
    }
    if (chaptersGeneratedCount < chaptersCount) {
      return 'CONTINUE_GENERATION';
    }
    if (chaptersGeneratedCount === chaptersCount && sectionsCount === 0) {
      return 'INITIAL_RENDERING';
    }
    if (chaptersGeneratedCount === chaptersCount && sectionsCount + 1 < chaptersCount) {
      return 'CONTINUE_RENDERING';
    }
    return 'ALL_COMPLETED';
  }

  useEffect(() => {
    switch (globalStatus) {
      case 'INITIAL_GENERATION':
        initialGeneration();
        break;
      case 'INITIAL_RENDERING':
        initialRendering();
        break;
      case 'ALL_COMPLETED':
        renderCompletedBook();
        break;
      case 'CONTINUE_GENERATION':
        continueChaptersGeneration();
        break;
      case 'CONTINUE_RENDERING':
        continueChaptersGeneration();
        break;
      default:
        break;
    }
  }, [globalStatus]);

  const initialRendering = () => {
    if (!bookData) {
      throw new Error('NO BOOK DATA');
    }

    const valuedChapters = bookData.chapters.filter(chapter => chapter.text);

    const blocksData: any = AIBookGenerator.build({
      chapters: valuedChapters.map(chapter => ({
        chapter: chapter.text || '',
        title: chapter.title,
        bookName: bookData.title
      })),
      coverUrl: bookData.coverImageUrl,
      bookTitle: bookData.title,
    });

    rxBlocks.next(blocksData);
  }

  const initialGeneration = async () => {
    if (!socket.current) {
      throw new Error('NO SOCKET CONNECTION');
    }
    if (!bookData) {
      throw new Error('NO BOOK DATA');
    }

    const generator = new AIBookGenerator();
    const blocksData = generator.createInitialData(bookData.title, selectBookImg);

    const sectionData = setIdsOrder(blocksData.blocks);
    rxBlocks.next(sectionData as any)
    getBookCoverFromSocket();

    socket.current.emit('ai-book-all-chapters', {
      chapters: bookData.chapters,
      bookId: bookData._id,
      token: getToken()
    });

    setChapterInProgress(0);
  }

  const getChaptersBySocket = () => {
    if (!socket.current) {
      return;
    }
    socket.current.on('response-book-all-chapters', (data) => {
      if (!data.chapterText || !data.chapterTitle) {
        return;
      }
      setAllChaptersGenerationResponse({
        text: data.chapterText as string,
        title: data.chapterTitle as string,
      });
    });
  }

  useEffect(() => {
    if (allChaptersGenerationResponse) {
      handleAllChapterGenerationResponse(allChaptersGenerationResponse);
    }
  }, [allChaptersGenerationResponse])

  const handleAllChapterGenerationResponse = (response: IChaptersGenerationResponse) => {
    if (!bookData) {
      throw new Error('NO BOOK DATA');
    }
    const { text, title } = response;

    //generator
    const generator = new AIBookGenerator();
    const blockData = generator.createTextBlock(
      extractChapterTitle(title),
      text,
      bookData.title
    );
    rxInsertSection.next({ section: blockData});

    let nextChapterIndex: number | undefined;
    bookData.chapters.forEach((currentChapter, index) => {
      if (currentChapter.title === title) {
        nextChapterIndex = index + 1;
      }
    });

    if (nextChapterIndex === undefined) {
      throw new Error(`Chapter with title ${title} not found`);
    }

    if (nextChapterIndex < bookData.chapters.length) {
      setChapterInProgress(nextChapterIndex);
    } else {
      setChapterInProgress(null);
    }
  }

  const getTemplateSections = async () => {
    const sections = await graphQlCall({
      queryTemplateObject: queries.GET_SECTIONS_BY_NAMES,
      values: {
        names: ['AI-BookChapter-1', 'AI-BookCover-1']
      }
    });
    rxSections.next(sections);
  }

  const saveBook = async (dataForSave: any) => {
    if (bookData?._id) {
      setBookSaveLoading(true);
      const coverData = await generateBookCover();
      const values: any = {
        id: bookData?._id,
        data: JSON.stringify(dataForSave),
        title: bookData.title,
        chapters: JSON.stringify(bookData.chapters)
      };

      if (coverData) {
        values.coverData = coverData;
        const initialImageLink = getCoverImage();
        if (initialImageLink) {
          values.initialImageLink = initialImageLink;
        }
      }

      await graphQlCall({
        queryTemplateObject: queries.UPDATE_BOOK_MUTATION,
        headerType: 'USER-AUTH',
        values,
      });
      setBookSaveLoading(false);
    }
    rxBookNeedSave.next(false);
  };

  const generateBookCover = async () => {
    if (!socket.current) {
      console.error('no socket');
      return;
    }
    const image = getCoverImage();
    const coverElement = document.getElementById('1');
    if (!coverElement) {
      console.error('no cover element');
      return;
    }
    coverElement.style.backgroundImage = `url("${image}")`;
    coverElement.style.backgroundSize = 'cover';

    const element = document.getElementById('1');
    const html = element?.outerHTML;
    if (!html) {
      console.error('could not get book cover html');
      return;
    }
    const payload = {
      html,
      width: 800,
      height: 1100,
    };
    const res: any = await getBySocket({
      payload,
      emitEventName: 'create-screenshot',
      resultEventName: 'screenshot-created',
      socket: socket.current,
    });

    return res.stringData;
  };

  const getCoverImage = () => {
    const allBlocks: any = { ...(blocks as any) };
    if (!allBlocks?.blocks?.length) {
      return null;
    }
    const block: any = allBlocks.blocks[0];
    return block.image;
  };

  useEffect(() => {
    if (bookDataForSave && chapterInProgress === null) {
      saveBook(bookDataForSave);
    }
  }, [bookDataForSave]);

  const renderCompletedBook = () => {
    if (!bookData?.data) {
      throw new Error('NO BOOK DATA');
    }
    rxBlocks.next(bookData.data);
  }

  const continueChaptersGeneration = async () => {
    if (!bookData?.data || !bookData.chapters) {
      throw new Error('NO BOOK DATA/CHAPTERS');
    }
    if (!socket.current) {
      throw new Error('NO SOCKET');
    }
    getBookCoverFromSocket();
    const sectionsCount = bookData.data?.blocks?.length || 0;

    if (sectionsCount) {
      rxBlocks.next(bookData.data);
    } else {
      initialRendering();
    }

    let chaptersWithText = 0;
    bookData.chapters.forEach(chapter => {
      if (chapter.text?.length) {
        chaptersWithText++;
      }
    })

    if (chaptersWithText < bookData.chapters.length) {
      socket.current.emit('continue-book-generation', { bookId });
      setChapterInProgress(chaptersWithText);
    } else {
      setChapterInProgress(null);
    }
  }

  useEffect(() => {
    if (bookCoverFromSocket) {
      insertCoverToSection(1, bookCoverFromSocket);
    }
  }, [bookCoverFromSocket]);

  const insertCoverToSection = (id: number, coverImageUrl: string) => {
    if (!blocks) {
      console.error('NO BLOCKS')
      return;
    }
    let newBlocks = cloneDeep(blocks.blocks);
    let blockFound = false;
    newBlocks = newBlocks.map((block: any) => {
      if (block.id === id) {
        block = cloneDeep(block);
        block.image = coverImageUrl;
        
        blockFound = true;
      }
      return block;
    });

    if (!blockFound) {
      console.error('BLOCK NOT FOUND');
    }

    const result: any = {
      blocks: newBlocks,
      lastId: blocks.lastId,
    }

    rxBlocks.next(result)
  }

  const getBookCoverFromSocket = () => {
    if (!bookData?.coverImageUrl && socket.current) {
      socket.current.emit('get-book-cover', { bookId });
      socket.current.on('got-book-cover', data => {
        setBookCoverFromSocket(data.imageUrl);
      });
    }
  }

  const reorderChapter = (dragIndex: number, dropIndex: number) => {
    if (!bookData) {
      return;
    }

    let newChapters: IBookChapter[] = [...bookData.chapters];
    const tempChapter = newChapters.splice(dragIndex, 1)[0];
    newChapters.splice(dropIndex, 0, tempChapter);
    const newBookData = {...bookData};
    newBookData.chapters = newChapters;
    setBookData(newBookData);

    rxReorderSection.next({fromIndex: dragIndex+1, toIndex:dropIndex+1} as any)

  };

  const deleteChapter = async (index: number) => {
    if (!bookData) {
      return;
    }
    
    rxRemoveSection.next({index: index+1});

  };

  const onChapterTitleEdited = async (value: string, index: number) => {
    if (value.trim().length === 0 || !bookData) {
      return;
    }
    updateChapterData(index, {
      title: value
    })
  };

  const addChapter = async (index: number, action: 'add'| 'insert') => {
    if (!socket.current || !bookData) {
      return;
    }
    // await saveBook(blocks);
    const tempChapter: IBookChapter = {
      comments: [],
      title: 'new chapter',
    }
    let newChapters: IBookChapter[] = [];
    if (action === 'insert') {
      bookData.chapters.forEach((chapter, chapterIndex) => {
        if (chapterIndex === index) {
          newChapters.push(tempChapter);
        }
        newChapters.push(chapter);
      });
    } else {
      newChapters = [...bookData.chapters];
      newChapters.push(tempChapter);
    }
    setChapterInProgress(index);


    const newBookData = cloneDeep(bookData);
    newBookData.chapters = newChapters;
    setBookData(newBookData);

    const payloadAddChapter = {
      bookId,
      token: getToken(),
      positionBefore: index,
      action: action,
    };

    //getting new chapter
    const dataAddChapter: any = await getBySocket({
      emitEventName: 'ai-book-add-chapter',
      resultEventName: 'response-book-add-chapter',
      payload: payloadAddChapter,
      socket: socket.current,
    });

    //setting titile 
    if (!newChapters[index]) {
      newChapters.push({
        title: dataAddChapter.chapterTitle,
        text: '',
        comments: []
      })
    } else {
      newChapters[index].title = dataAddChapter.chapterTitle;
    }
    setNewChaptersInBook(newChapters);


    //adding placeholder for new chapter
    const generator = new AIBookGenerator();
    const blockDataPlaceholder = generator.createTextBlock(
      extractChapterTitle(dataAddChapter.chapterTitle as string),
      'text generation in progress...',
      bookData.title
    );

    rxInsertSection.next({
      index: index + 1,
      section: blockDataPlaceholder,
    });

    //generating text for chapter 
    const payloadChapterGenerateText = {
      bookId,
      token: getToken(),
      chapterIndex: index,
      requireContent: 'text',
    };
    const resChapterGenerateTex: any = await getBySocket({
      payload: payloadChapterGenerateText,
      emitEventName: 'ai-book-re-chapter',
      resultEventName: 'response-book-re-chapter',
      socket: socket.current,
    });

    const blockData = generator.createTextBlock(
      extractChapterTitle(dataAddChapter.chapterTitle as string),
      resChapterGenerateTex.text,
      bookData.title
    );

    rxReplaceSection.next({
      index: index + 1,
      section: blockData,
    });

    newChapters[index].text = resChapterGenerateTex.text;
    setNewChaptersInBook(newChapters);
    setChapterInProgress(null);
  }

  const onChapterRegenerate = async (index: number) => {
    if (!socket.current || !bookData) {
      return;
    }
    setChapterInProgress(index);
    await saveBook(blocks);
    const regenerateTitlePayload = {
      bookId,
      token: getToken(),
      requireContent: 'title',
      chapterIndex: index,
    };
    const regenerateTitleRes: any = await getBySocket({
      payload: regenerateTitlePayload,
      emitEventName: 'ai-book-re-chapter',
      resultEventName: 'response-book-re-chapter',
      socket: socket.current,
    });
    const regenerateChapterTextPayload = {
      bookId,
      token: getToken(),
      requireContent: 'text',
      chapterIndex: index,
    };
    const regenerateChapterTextRes: any = await getBySocket({
      payload: regenerateChapterTextPayload,
      emitEventName: 'ai-book-re-chapter',
      resultEventName: 'response-book-re-chapter',
      socket: socket.current,
    });
    const generator = new AIBookGenerator();
    const blockData = generator.createTextBlock(
      extractChapterTitle(regenerateTitleRes.text as string),
      regenerateChapterTextRes.text,
      bookData.title
    );
    rxRemoveSection.next({index: index + 1});
    rxInsertSection.next({
      index: index + 1,
      section: blockData,
    });

    updateChapterData(index, {
      text: regenerateChapterTextRes.text,
      title: regenerateTitleRes.text
    })
    setChapterInProgress(null);
  };

  const onBookTitleEdited = async (value: string) => {
    if (value.trim().length === 0 || !bookData) {
      return;
    }
    const newBookData = cloneDeep(bookData);
    newBookData.title = value;
    setBookData(newBookData);
  };

  const addImageChapter = async (indexSection: number) => {
    if (!bookData) {
      return;
    }
    setChapterInProgress(-1);

    const newChapters: IBookChapter[] = [];
    bookData.chapters.forEach((chapter, chapterIndex) => {
      if (chapterIndex === indexSection - 1) {
        newChapters.push({
          title: IMAGE_SECTION_TITLE,
          comments: [],
          text: "image"
        })
      }
      newChapters.push(chapter);
    });
    setNewChaptersInBook(newChapters);

    const generator = new AIBookGenerator();
    const blockData = generator.createBlankPage();

    rxInsertSection.next({
      index: indexSection,
      section: blockData,
    });
    setChapterInProgress(null);
  };

  const updateChapterData = (chapterIndex: number, data: any) => {
    if (!bookData?.chapters || chapterIndex >= bookData?.chapters.length) {
      return;
    }
    const newChapters = [...bookData.chapters];
    newChapters[chapterIndex] = {
      ...newChapters[chapterIndex],
      ...data
    }
    const newBookData = cloneDeep(bookData);
    newBookData.chapters = newChapters;
    setBookData(newBookData);
  }

  const setNewChaptersInBook = (newChapters: IBookChapter[]) => {
    if (!bookData) {
      return;
    }
    const newBookData = cloneDeep(bookData);
    newBookData.chapters = newChapters;
    setBookData(newBookData);
  }

  return (
    <div className={s.bookEditorWrapper}>
      <div className={s.bookEditorSideMenu}>
        <BookInfoBlockEditor
          bookTitle={bookData?.title ? bookData?.title : ''}
          onBookTitleEdited={onBookTitleEdited}
          chapters={bookData?.chapters || []}
          onChapterTitleEdited={onChapterTitleEdited}
          deleteChapter={deleteChapter}
          onChapterAdd={addChapter}
          reorderChapter={reorderChapter}
          endReorderChapter={() => {}}
          onChapterRegenerate={onChapterRegenerate}
          addingChapterIndex={chapterInProgress}
          onRestoreCover={() => recoverBookCover()}
        />
      </div>
      <div className={s.bookEditorView}>
        <BookEditorView inProgress={chapterInProgress !== null} />
      </div>
      {bookSaveLoading && <SaveSpinner />}
    </div>
  );
};

export default BookEditor;
